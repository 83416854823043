body {
  margin: 0;
  background-color: #fff;
  line-height: initial;
  /* overflow-x: hidden !important; */
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  /* -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}
@media (max-width: 1025px) {
  .cardGrid {
    display: grid;
    grid-gap: 10px;
    padding: 20px;
    grid-template-rows: minmax(150px, 1fr);
    grid-auto-flow: column;
    overflow-x: scroll;
  }
}
@media (min-width: 1025px) {
  .cardGrid {
    display: none;
    /* overflow-x: scroll; */
  }
}
.navOpenDropDown {
  transition: height 0.5s ease-out;
  height: 350px;
  overflow: hidden;
  background: #fff;
  padding-top: 4rem;
}
.navOpenCloseDown {
  transition: height 0.5s ease-out;
  height: 0px;
  overflow: hidden;
  padding: 0px;
  background: #fff;
  padding: "0px"
}
@tailwind base;
@tailwind components;
@tailwind utilities;

.pagination {
  margin: 15px auto;
  display: flex;
  list-style: none;
  outline: none;
 
}
.pagination > .active > a{
  background-color: #47ccde ;
  border-color: #47ccde ;
  color: #fff;
}
.pagination > li > a{
  
  padding: 2px 10px;
  outline: none;
  cursor: pointer;
  font-size: 20px;
} 
.pagination > .active > a, .pagination > .active > span, .pagination > .active > a:hover, .pagination > .active > span:hover, .pagination > .active > a:focus, .pagination > .active > span:focus{
  background-color: #47ccde ;
  border-color: #47ccde;
  outline: none ;
}
.pagination > li > a, .pagination > li > span{
  color: #47ccde
}
.pagination > li:first-child > a, .pagination > li:first-child > span, .pagination > li:last-child > a, .pagination > li:last-child > span{
  border-radius: unset
}